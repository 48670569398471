import { Row, Col, Card, Image } from "../../bootstrap.js";
import "./programs.css";
// import { Hexagon } from "./Hexagon.js";
// import Immunoprotein from "./Immunoprotein.js";
// import "./hexagon.css";
import { useMediaQuery } from "usehooks-ts";

export function Programs() {
  const isMobile = useMediaQuery("(max-width: 837px)");

  const programsHome1 = [
    "Immunoproteins",
    "Squeeze-Out Model",
    "Drug Delivery",
    "Computational Techniques",
    "Multisensory Tools",
  ];

  const programsHome = [
    {
      title: "Immunoproteins",
      alt: "Immunoproteins that appear as a cluster of tight-knit 3-D spiral curls in purple, yellow and teal.",
     
    },
    {
      title: "Alveolar Interface",
      alt: "A zoomed in photo of alveolar interface in red.",
    },
    {
      title: "Drug Delivery",
      alt: "A diagram on how DPPC lipid can serve as a drug delivery method.",
    },
    {
      title: "Computational Methods",
      alt: "A force field of surfactant proteins.",
    },
    {
      title: "Multisensory Science", //STEM Tools for the Blind
      alt: "Human fingers trace the surface of a lithophane of a protein.",
    },
  ];

  const path = `/images/HomePics/projectHome${1}.png`;

  return (
    <div className="programsCont3D">
      
      <Row className="programRow1">
      <h3 className="redHeaderHome">Research Programs</h3>
      <p className="learnProject">
          Click to learn more about each ongoing program.
        </p>
        {/* <header>
        <h4>Programs</h4>
      </header> */}
        {/* <header className="projectsHeader">
        <h3>Projects</h3>
      </header> */}
      
        {programsHome.map((program, i) => (
          <Col
            xs={12}
            sm={6}
            md={6}
            lg={6}
            xl={3}
            // className={i === 2 && isMobile ? "programCont2" : "programCont"}
            className="programCont"
          >
            <>
              <a href="/Research" className="linkImage ">
                <div className="mag">
                  {/* <div className=""> */}
                    {/* <p className="programHome">
                      <i
                        class={`bi bi-${i + 1}-square number`}
                        aria-label={`Program ${i + 1}`}
                      ></i>
                      {`${program.title}`}
                    </p> */}

            {/* <Image src="/images/logo/shortHandleLens.svg" className="bgLens"></Image> */}
                    <Image
                      src={`/images/HomePics/program${i+1}Home.png`}
                      alt={`${program.alt}`}
                      className={i===0 ? "ImageProgram img-fluid circle fix" : "ImageProgram img-fluid circle"}
                    ></Image>
                    <div className="handle"></div>
                  {/* </div> */}
                </div>

                <p className="programHome">
                <i
                  class={`bi bi-${i + 1}-square number`}
                  aria-label={`Program ${i + 1}`}
                ></i>
                {`${program.title}`}
              </p>
              </a>
            </>
          </Col>
          
        ))}
        <div className="bottomPrograms"></div>
        
      </Row>
      <Row className="justify-content-center programRow">  {/* Center the content horizontally */}
      <Col xs="auto" className="d-flex justify-content-center">  {/* Center the image */}
        <Image
          src={`/images/HomePics/program_pulmo.png`}
          alt= "Surfactant System"
          className="img-fluid bigimage"  // Apply the custom class
          
        />
        
      </Col>
      <p className="img_des">
          Pulmonary Surfactant System
        </p>
        <p className="img_des1">We model pulmonary surfactant (PS) at the molecular level using various computational techniques to understand the different components of pulmonary surfactant to advance human health.</p>
    </Row>
      
      
   
    </div>
  );
}

// <Image
// src={`/images/HomePics/projectHome${i + 1}.png`}
// alt={`${program.alt}`}
// className="ImageProgram"
// ></Image>
